import React from "react";
import Container from "../components/container";
import SEO from "../components/seo";
import Layout from "../containers/layout";

const ChetPage = () => {

  const title = "Conservative Power Talk | About Chet"
  const description = "All about Chet"
  const keywords = ["Conservative", "Real Conservative", "Christians in America"]

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
      />
      <Container>
        <h1>Hi I'm Chet</h1>

        <br />
        
        <div>I am Chet Powers. I am a real conservative American who lives following the teachings of our Lord and Savior Jesus Christ. I do not like the people who say they are conservatives and do not follow the preachings of Christianity.</div>

        <br />

        <div>Thank you to my son Randy, for making this website for me.</div>

        <br />

        <div>God Bless.</div>

        <br />

        <div>-Chet</div>
      </Container>
    </Layout>
  );
};

export default ChetPage;
